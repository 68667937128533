import img1 from "../Assets/Gallery/img1.jpg"
import img2 from "../Assets/Gallery/img2.jpg"
import img3 from "../Assets/Gallery/img3.jpg"
import img4 from "../Assets/Gallery/img4.jpg"
import img5 from "../Assets/Gallery/img5.jpg"
import img6 from "../Assets/Gallery/img6.jpg"
import img7 from "../Assets/Gallery/img7.jpg"
import img8 from "../Assets/Gallery/img8.jpg"
import img9 from "../Assets/Gallery/img9.jpg"
import img10 from "../Assets/Gallery/img10.jpg"
import img11 from "../Assets/Gallery/img11.jpg"




const clicksdata = [
  {
    "id": 1,
    "src": img1,
    "title": "Chainsaw Man"
  },
  {
    "id": 2,
    "src": img2,
    "title": "Image 2"
  },
  {
    "id": 3,
    "src": img3,
    "title": "Image 3"
  },
  {
    "id": 4,
    "src": img4,
    "title": "Image 3"
  },
  {
    "id": 5,
    "src": img5,
    "title": "Image 3"
  },
  {
    "id": 6,
    "src": img6,
    "title": "Image 3"
  },
  {
    "id": 7,
    "src": img7,
    "title": "Image 3"
  },
  {
    "id": 8,
    "src": img8,
    "title": "Image 3"
  },
  {
    "id": 9,
    "src": img9,
    "title": "Image 3"
  },
  {
    "id": 10,
    "src": img10,
    "title": "Image 3"
  },
  {
    "id": 11,
    "src": img11,
    "title": "Image 3"
  },
];

export default clicksdata;
