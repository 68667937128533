import React from 'react'
import UnderConstruction from '../Components/UnderConstruction'

const Blogs = () => {
  return (
    <div >
        <UnderConstruction/>
    </div>
  )
}

export default Blogs